<template>
  <div class="bg">
    <div class="container">
      <div class="titleDocument">
        已有账号，<a @click="login">立即登录</a>
      </div>
      <div class="main">
        <PlateTitle
          title="忘记密码"
        />
      </div>
      <div class="steps">
        <a-steps
          :current="current"
        >
          <a-step
            title="验证账户信息"
          />
          <a-step
            title="设置新密码"
          />
          <a-step
            title="完成找回"
          />
        </a-steps>
      </div>
      <AutInfor v-if="current === 0" />
      <NewPassword v-if="current === 1" />
      <Success v-if="current === 2" />
    </div>
  </div>
</template>

<script>
import PlateTitle from '@/components/PlateTitle';
import AutInfor from './components/autInformation.vue';
import NewPassword from './components/newPassword.vue';
import Success from './components/success.vue';
export default {
  name: 'ForgotPassword',
  components: {
    PlateTitle,
    AutInfor,
    NewPassword,
    Success,
  },
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    login() {
      this.$router.push({ name: 'login' });
    },
    // 下一步传值
    getCurrent(e) {
      this.current = e;
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  background-color: #F0F3F7;
  overflow: hidden;
}
.container {
  background-color: #fff;
  width: 1180px;
  min-height: 676px;
  margin: 30px auto 80px;
  position: relative;
  .titleDocument {
    position: absolute;
    top: 36px;
    right: 50px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
}
.main {
  padding: 30px 50px 95px;
}
.steps {
  width: 600px;
  margin: 0 auto;
}

</style>
