<template>
  <div class="form">
    <a-form
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
      @submit="handleSubmit"
    >
      <a-form-item
        label="新密码"
        has-feedback
      >
        <a-input-password
          v-decorator="['password', {rules: [{validator: compareToFirstPassword,},],}]"
          placeholder="请输入新密码"
          style="width: 280px"
        />
      </a-form-item>
      <a-form-item
        label="确认密码"
        has-feedback
      >
        <a-input-password
          v-decorator="[ 'conPassword',{rules: [{validator: compareToFirstConPassword,},],},]"
          placeholder="重复上方输入的密码"
          style="width: 280px"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button
          type="primary"
          html-type="submit"
        >
          下一步
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { retrieve } from '@/api/login/register.js';
export default {
  name: 'NewPassword',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
    };
  },
  methods: {
    // 自定义校验
    compareToFirstPassword(rule, value, callback) {
      const pattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[a-zA-Z0-9]{8,16}$/;
      if (pattern.test(value)) {
        callback();
      } else {
        callback('密码须8-16位且包含数字、大小写字母');
      }
    },
    compareToFirstConPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('密码输入不一致');
      } else {
        callback();
      }
    },
    // 下一步
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          retrieve({ pwd: values.password, confirmPwd: values.conPassword }).then(res => {
            if (res.data.status === 'FAIL') {
              this.$message.warning(res.data.errorMsg);
            } else {
              this.$parent.getCurrent(2);
            }
          });
        }
      });
    },
  },

};
</script>

<style lang="less" scoped>
.form {
  display: flex;
  justify-content: center;
  margin: 100px 0;
  /deep/ .ant-form-item-label {
    width: 100px;
  }
  /deep/ .ant-form-item label {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  /deep/ .ant-input {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 3px;
  }
  button {
    width: 280px;
    height: 44px;
    background: #007CE2;
    box-shadow: 0px 5px 8px 0px rgba(136, 189, 232, 0.3);
    border-radius: 3px;
    margin-left: 21px;
  }
}
</style>
